export class SettingsService {

    public idUsuario: number = 0;
    public idPersona: number = 0;
    public idEntidad: number = 0;
    public rolUsuario: string;
    public esOperador: boolean;
    public userCorreo: string;
    public nombres: string;
    public cargo: string;
    public usuario: object;
    public estado_finalizacion: number;

    constructor() {
    }

    setUsuario(usuario: any) {
        console.log("Rol recibido: ", usuario.rol); // Verifica el valor del rol
        this.usuario = usuario;
        this.idUsuario = usuario.usuario.id;
        this.idPersona = usuario.persona.id;
        this.idEntidad = usuario.persona.entidad_id;
        this.userCorreo = usuario.usuario.email;
        this.estado_finalizacion = usuario.persona.estado_finalizacion;
        this.nombres = usuario.persona.nombres+' '+usuario.persona.apellido_paterno+' '+usuario.persona.apellido_materno;
        this.cargo = usuario.persona.cargo.toUpperCase();
        this.setRolUsuario(usuario.rol);
    }

    setRolUsuario(rol: string) {
        this.rolUsuario = rol.toUpperCase(); // Convertir el rol a mayúsculas
        this.esOperador = (this.rolUsuario === "SUPER ADMINISTRADOR" ||
                           this.rolUsuario === "ADMINISTRADOR" ||
                           this.rolUsuario === "APROBADOR");
    }
    
    getEsOperador() {
        return this.esOperador;
    }

    getIdUsuario() {        
        return this.idUsuario;
    }

    getUserCorreo() {
        return this.userCorreo;
    }
    getUserNombre() {
        return this.nombres;
    }
    getUserCargo() {
        return this.cargo;
    }
    getPersonaID() {
        return this.idPersona;
    }

    getEstadoFinalizacion() {
        return this.estado_finalizacion;
    }

    setEstadoFinalizacion(estado : number) {
        return this.estado_finalizacion = estado;
    }
}

export default new SettingsService();